import React, { useRef, useState } from 'react'
import slugify from 'slugify'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import ReactPlayer from 'react-player'
import parseUrl from 'parse-url'

import {
  Chunk,
  Wrapper,
  Person,
  PersonLink,
  Name,
  Title,
  IconWrapper,
  TextWrapper,
  Center,
} from './styles'

import Video from './Video'

const getThumbnail = url => {
  urlObj = parseUrl(url)
  const id = urlObj.pathname.split('/')[1]
}

const Content = ({ videos }) => (
  <div>
    <Wrapper bg="white" color="black">
      <Center>
        <Chunk>
          {/* <h3>NASP Team</h3> */}
          {videos.map(video => (
            <Video {...video.node} />
          ))}
        </Chunk>
      </Center>
    </Wrapper>
  </div>
)

export default Content
