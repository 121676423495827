import React from 'react'

import { Section, Text } from './styles'

const Head = () => (
  <div>
    <Section bg="black" color="white" pt="12rem" pb="10rem">
      <Text large>
        NASP is comprised of a network of artists, writers, photographers,
        filmmakers, activists, academics, and public servants.
      </Text>
    </Section>
  </div>
)

export default Head
