import React, { useRef, useState } from 'react'
import slugify from 'slugify'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import ReactPlayer from 'react-player'
import parseUrl from 'parse-url'
import Modal from 'react-modal'
import ScrollLock from 'react-scrolllock'
import { Portal } from 'react-portal'

import {
  Chunk,
  Wrapper,
  Person,
  PersonLink,
  Name,
  Title,
  IconWrapper,
  TextWrapper,
  VideoWrap,
  PlayerWrapper,
} from './styles'

const Video = ({ id, story, context, title, video }) => {
  const ref = useRef()
  const [show, setShow] = useState(false)

  const openModal = e => {
    setShow(true)
    ref.current.showPreview()
  }

  const closeModal = e => {
    setShow(false)
  }

  Modal.setAppElement('#___gatsby')

  return (
    <VideoWrap key={id}>
      {title && (story || context) && (
        <>
          {video && (
            <PlayerWrapper>
              <ReactPlayer
                ref={ref}
                url={video}
                className="react-player"
                // playing
                light={true}
                width="100%"
                onClick={openModal}
                height="100%"
                // style={{ maxHeight: '80vh' }}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                config={{
                  vimeo: { playerOptions: { background: false } },
                }}
              />
            </PlayerWrapper>
          )}
          <Modal
            isOpen={show}
            onRequestClose={closeModal}
            style={{
              overlay: { background: 'rgba(0,0,0,0.85)', zIndex: 10 },
              content: {
                color: 'white',
                background: 'none',
                border: 'none',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                top: '50%',
                padding: 0,
                height: 'auto',
                width: '90vw',
                // maxHeight: '80vh',
                maxWidth: '80rem',
                transform: 'translate(-50%, -50%)',
              },
            }}
          >
            <ScrollLock />
            <Portal>
              <button
                style={{
                  color: 'white',
                  fontSize: '1.25rem',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'fixed',
                  top: '0.5rem',
                  right: '0.5rem',
                  margin: '1rem',
                  zIndex: 11,
                  background: 'none',
                  backgroundShadow: 'none',
                  appearance: 'none',
                  border: 'none',
                }}
                onClick={closeModal}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  size="1x"
                  // fixedWidth
                  style={{ marginRight: '0.5rem', display: 'inline-block' }}
                />
                close
              </button>
            </Portal>

            {video && show && (
              <PlayerWrapper>
                <ReactPlayer
                  url={video}
                  playing
                  width="100%"
                  height="100%"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  config={{
                    vimeo: { playerOptions: { background: false } },
                  }}
                />
              </PlayerWrapper>
            )}
            {/* {story && <Title>{'story'}</Title>}
            {context && <Title>{'context'}</Title>} */}
          </Modal>
        </>
      )}
    </VideoWrap>
  )
}
export default Video
