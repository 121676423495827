import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import ChangeTitle from 'containers/change-title'
import Head from '../components/Pages/Videos/Head'
import Content from '../components/Pages/Videos/Content'

import config from '../../data/SiteConfig'

const VideosPage = ({ data }) => {
  return (
    <div className="videos-container">
      <Helmet title={`Videos | ${config.siteTitle}`} />
      <ChangeTitle title="Videos" />
      <Head />
      <Content videos={data.videos.edges} />
    </div>
  )
}

export default VideosPage

/* eslint no-undef: "off" */
export const query = graphql`
  query VideosQuery {
    videos: allContentfulComponentBodyImageOrVideo(
      filter: { video: { ne: null } }
    ) {
      edges {
        node {
          id
          video
          title
          context {
            id
            title
            slug
          }
          story {
            id
            title
            slug
          }
        }
      }
    }
  }
`
